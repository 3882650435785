/* Heights, borders, margins and padding. */
/* Application colors */
.main {
  height: 100vh;
  width: 100%;
  display: flex;
  background-color: #f9f7f5;
  overflow: hidden; }
  .main .container {
    display: flex;
    flex-direction: column;
    flex-grow: 1; }
    .main .container .header {
      border-bottom: 0;
      min-height: 98px; }
    .main .container .svg-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 2px; }
    .main .container .pd-icon {
      background-color: #6554c0; }
    .main .container .activities-and-anchor-library {
      min-height: 0;
      height: 100%;
      width: 100%; }
      .main .container .activities-and-anchor-library .panels {
        height: calc(100% - 85px); }
        .main .container .activities-and-anchor-library .panels > div {
          height: 100%; }
    .main .container .button-container {
      display: flex; }
      .main .container .button-container .button {
        margin-left: 16px;
        min-width: 140px;
        border-radius: 2px;
        background-color: transparent !important; }
        .main .container .button-container .button button {
          padding: 0 16px; }
          .main .container .button-container .button button span {
            display: inline-block;
            width: 24px;
            height: 28px;
            border-radius: 50%;
            font-size: 12px;
            font-weight: bold;
            line-height: 24px;
            text-align: center;
            margin-left: 10px; }
    .main .container .header {
      background-color: #f9f7f5; }
    .main .container .content {
      margin-left: auto;
      margin-right: auto; }
    .main .container .tabs {
      width: 100%;
      border-bottom: 1px solid #e0e0e0;
      border-top: 1px solid #e0e0e0; }
