/* Heights, borders, margins and padding. */
/* Application colors */
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: #fff;
  max-width: 100%; }

button {
  border: none;
  cursor: pointer;
  outline: none; }
