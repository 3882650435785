.assessments {
  min-height: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%; }

.button {
  background-color: #00cfff;
  border-color: #00cfff;
  padding: 0 30px; }
  .button:hover {
    background-color: #00a8ce;
    border-color: #00a8ce; }
