.base-btn {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  min-width: 100px;
  padding: 0 14px;
  height: 34px;
  transition: all 0.2s ease-in-out;
  border: 2px solid;
  border-radius: 2px;
  white-space: nowrap;
  position: relative;
  min-width: 100px; }

.base-btn--color-secondary {
  background-color: #01d9a9;
  color: #fff;
  border-color: #01d9a9; }
  .base-btn--color-secondary:hover {
    background-color: #50e6c4;
    border-color: #50e6c4; }

.base-btn--color-secondary-alt {
  background-color: #50e6c4;
  color: #fff;
  border-color: #50e6c4; }
  .base-btn--color-secondary-alt:hover {
    background-color: #b4f4e6;
    border-color: #b4f4e6; }

.base-btn--color-primary {
  background-color: #0a54ff;
  color: #fff;
  border-color: #0a54ff; }
  .base-btn--color-primary:hover {
    background-color: #5184ff;
    border-color: #5184ff; }

.base-btn--color-dark {
  background-color: #000;
  color: #fff;
  border-color: #000; }
  .base-btn--color-dark:hover {
    background-color: #212121;
    border-color: #212121; }

.base-btn--color-violet {
  background-color: #7560ee;
  color: #fff;
  border-color: #7560ee; }
  .base-btn--color-violet:hover {
    background-color: #7970ff;
    border-color: #7970ff; }

.base-btn--color-light-blue {
  background-color: #00cfff;
  color: #fff;
  border-color: #00cfff; }
  .base-btn--color-light-blue:hover {
    background-color: #00a8ce;
    border-color: #00a8ce; }

.base-btn--size-large {
  height: 40px;
  font-size: 16px; }

.base-btn--secondary-bordered {
  background-color: transparent;
  border-color: #01d9a9;
  color: #01d9a9; }
  .base-btn--secondary-bordered:hover {
    background-color: #d9faf2;
    border-color: #01d9a9; }
  .base-btn--secondary-bordered.base-btn--custom-hover:hover {
    border-color: #01d9a9; }
    .base-btn--secondary-bordered.base-btn--custom-hover:hover span {
      opacity: 1; }

.base-btn--primary-bordered {
  background-color: #fff;
  border-color: #0a54ff;
  color: #0a54ff; }
  .base-btn--primary-bordered:hover {
    background-color: #aac3ff; }

.base-btn--color-danger {
  background-color: #ff2a1e;
  border-color: #ff2a1e;
  color: #fff; }
  .base-btn--color-danger:hover {
    background-color: #ff2a1e;
    border-color: #ff2a1e;
    color: #fff;
    opacity: 0.9; }

.base-btn--danger-bordered {
  background-color: #fff;
  border-color: #ff2a1e;
  color: #ff2a1e; }
  .base-btn--danger-bordered:hover {
    background-color: #ff2a1e;
    border-color: #ff2a1e;
    color: #fff;
    opacity: 0.9; }

.base-btn--color-warning {
  background-color: #feb63c;
  border-color: #feb63c;
  color: #fff; }
  .base-btn--color-warning:hover {
    background-color: #feb63c;
    border-color: #feb63c;
    color: #fff;
    opacity: 0.9; }

.base-btn--disabled {
  background-color: #f9f7f5;
  border-color: #f9f7f5;
  color: #666666;
  pointer-events: none;
  cursor: not-allowed; }
  .base-btn--disabled:hover {
    background-color: #f9f7f5;
    border-color: #f9f7f5;
    color: #737373; }

.base-btn--disabled-bordered {
  background-color: transparent;
  border-color: #e0e0e0;
  color: #e0e0e0;
  pointer-events: none;
  cursor: not-allowed; }

.base-btn--flat {
  background-color: transparent;
  border-color: transparent;
  color: #0a54ff; }
  .base-btn--flat:hover {
    background-color: transparent;
    border-color: transparent;
    color: #5184ff; }

.base-btn--block {
  width: 100%; }

.base-btn--transparent {
  background-color: transparent; }

.base-btn--rounded {
  border-radius: 24px; }

.hoverElement {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 !important;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #01d9a9;
  opacity: 0;
  transition: opacity 0.2s ease-in-out; }

.badge--secondary, .badge--danger, .badge--primary, .badge--dark {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 21px;
  max-width: 21px;
  height: 21px;
  background-color: #fff;
  text-align: center;
  border-radius: 2px;
  font-weight: 500;
  margin-left: 10px; }

.badge--secondary {
  color: #01d9a9; }

.badge--danger {
  color: #ff2a1e; }

.badge--primary {
  color: #0a54ff; }

.badge--dark {
  color: #000; }

.badge--disabled {
  background-color: #757575;
  color: #fff; }
