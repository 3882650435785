/* Heights, borders, margins and padding. */
/* Application colors */
.rubric-bank-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column; }
  .rubric-bank-container .add-from-library {
    margin-top: 8px; }
  .rubric-bank-container .rubric-bank-content {
    height: 100%;
    width: 100%;
    display: flex;
    margin-top: 16px; }
    .rubric-bank-container .rubric-bank-content .search-box-container {
      min-width: 250px;
      width: 250px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding: 0 30px; }
    .rubric-bank-container .rubric-bank-content .rubric-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end; }
