.floating-btn {
  display: inline-block;
  cursor: pointer;
  width: 34px;
  height: 34px;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  white-space: nowrap;
  position: relative;
  padding: 0; }

.buttonInner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }

.floating-btn--color-warning {
  background-color: #f81d1d;
  color: #fff; }
  .floating-btn--color-warning:hover {
    background-color: #f81d1d; }

.floating-btn--color-dark {
  background-color: #000;
  color: #fff; }
  .floating-btn--color-dark:hover {
    background-color: #212121; }

.floating-btn--color-secondary {
  background-color: #01d9a9;
  color: #fff; }
  .floating-btn--color-secondary:hover {
    background-color: #50e6c4; }

.floating-btn--color-secondary-alt {
  background-color: #d9faf2;
  color: #01d9a9; }
  .floating-btn--color-secondary-alt svg {
    fill: #01d9a9; }
  .floating-btn--color-secondary-alt:hover {
    background-color: #b4f4e6; }

.floating-btn--color-primary {
  background-color: #0a54ff;
  color: #fff; }
  .floating-btn--color-primary svg {
    fill: #fff; }
  .floating-btn--color-primary:hover {
    background-color: #5184ff; }

.floating-btn--color-primary-alt {
  background-color: #e0eaff;
  color: #0a54ff; }
  .floating-btn--color-primary-alt svg {
    fill: #0a54ff; }
  .floating-btn--color-primary-alt:hover {
    background-color: #aac3ff; }

.floating-btn--color-default {
  background-color: #fff;
  color: #757575; }
  .floating-btn--color-default svg {
    fill: #757575; }
  .floating-btn--color-default:hover {
    background-color: #f9f7f5; }

.floating-btn--color-white-transparent {
  background-color: #000;
  color: #fff; }
  .floating-btn--color-white-transparent svg {
    fill: #fff; }

.floating-btn--size-large {
  width: 40px;
  height: 40px; }

.floating-btn--size-small {
  width: 24px;
  height: 24px; }

.floating-btn--disabled {
  background-color: #e0e0e0;
  color: #fff;
  pointer-events: none;
  cursor: not-allowed; }
  .floating-btn--disabled svg {
    fill: #757575; }
  .floating-btn--disabled:hover {
    background-color: #e0e0e0;
    color: #fff; }
